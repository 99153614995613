import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { Disclosure, Popover, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ShoppingBagIcon } from '@heroicons/react/20/solid'

// import Flag from 'react-world-flags'
import ReactCountryFlag from "react-country-flag"
// import FlagIconFactory from 'react-flag-icon-css'

// import logo from '../assets/images/logo4light.png'
import logo from '../assets/images/logo4dark.png'
import carts from "../data/carts.json";
import PopoverCarts from '../overlays/Popover/Carts'

import { useTranslation } from 'react-i18next';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Navbar() {
  // const FlagIcon = FlagIconFactory(React)

  const {t,i18n} = useTranslation();

  const localeCountries = [
    { lang: 'en', code:"us", label: 'English',  },
    { lang: 'es', code:"ec", label: 'Español',  },
    { lang: 'zh', code:"cn", label: '简体中文',  },
  ];

  const getLocaleCountry = () => {
    return localeCountries.find(item => item.lang === i18n.language).code;
  }

  const [country, setCountry] = React.useState(getLocaleCountry());

  React.useEffect(() => {
    let lang = localeCountries.find(item => item.code === country).lang;
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  }, [country]);



    const navItems = [
        { name: t('navigation.home'),     href: '/' },
        { name: t('navigation.products'), href: '/products' },
        { name: t('navigation.about'),    href: '/about' },
        { name: t('navigation.contact'),  href: '/contact' },
        { name: t('navigation.shop'),    href: '/shop' },
    ]

  return (
    <Disclosure as="nav" className="bg-tutto-primary shadow sticky top-0 z-40">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-24 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tutto-secondary">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-20 w-auto"
                    src={logo}
                    alt="Tutto Capsule"
                  />
                </div>
                <div className="hidden md:ml-6 md:flex md:justify-center md:items-center md:x-space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {/* <a
                    href="/"
                    className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    Dashboard
                  </a>
                  <a
                    href="/"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Team
                  </a> */}
                  {navItems.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) => isActive ?
                      "inline-flex items-center px-8 h-12 pt-1 text-sm font-semibold text-white bg-tutto-secondary" :
                      "inline-flex items-center px-8 h-12 pt-1 text-sm font-semibold text-white "
                  }
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Popover className="ml-4 flow-root text-sm lg:relative lg:ml-8">
                    <Popover.Button className="group -m-2 flex items-center p-2">
                        <ShoppingBagIcon
                        className="h-6 w-6 flex-shrink-0 text-white group-hover:text-gray-500"
                        aria-hidden="true"
                        />
                        <span className="ml-2 text-sm font-medium text-white">{carts.length}</span>
                        <span className="sr-only">items in cart, view bag</span>
                    </Popover.Button>
                    <PopoverCarts />
                  </Popover>
                  {/* <button
                    type="button"
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-tutto-dark px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-tutto focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5 hidden" aria-hidden="true" />
                    My Cart
                  </button> */}
                </div>
                <div className=" md:ml-4 md:flex md:flex-shrink-0 md:items-center">

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex text-sm focus:outline-none focus:ring-2 focus:ring-tutto-secondary focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <ReactCountryFlag className="h-8 !text-3xl" countryCode={country} />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-12 origin-top-right rounded-md bg-white py-1 shadow-lg rig-1 ring-tutto-secondary ring-opacity-5 focus:outline-none">
                        {
                          localeCountries.map((item) => (
                            <Menu.Item key={item.code}>
                              {({ active }) => (
                                <button
                                  onClick={() => setCountry(item.code)}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block p-2 text-sm text-gray-700'
                                  )}
                                >
                                  <ReactCountryFlag className="h-8 !text-3xl" countryCode={item.code} alt={item.label} />
                                </button>
                              )}
                            </Menu.Item>
                          ))
                        }
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {/* <Disclosure.Button
                as="a"
                href="/"
                className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700 sm:pl-5 sm:pr-6"
              >
                Dashboard
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
              >
                Team
              </Disclosure.Button> */}
              {
                navItems.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) => isActive ?
                      "block py-2 pl-3 pr-4 text-base font-medium text-white sm:pl-5 sm:pr-6 bg-tutto-secondary" :
                      "block py-2 pl-3 pr-4 text-base font-medium text-white sm:pl-5 sm:pr-6 hover:bg-tutto-secondary hover:text-gray-700 "
                  }
                  >
                    <Disclosure.Button>{item.name}</Disclosure.Button>
                  </NavLink>
                ))
              }
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
