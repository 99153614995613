import React from 'react'
import { useTranslation } from 'react-i18next';
import { FaTruck, FaWhatsappSquare, FaShieldAlt, FaBars } from 'react-icons/fa'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


import Brands from '../../components/Section/Brands'

import banner1 from '../../assets/images/banner1.jpg'
import banner2 from '../../assets/images/banner2.png'
import banner3 from '../../assets/images/banner3.png'

export default function Home() {

  const { t } = useTranslation();

  const features = [
    {
      name: t('home.features_shipping'),
      description: t('home.features_shipping_description'),
      icon: FaTruck
    },


    {
      name: t('home.features_support'),
      description: t('home.features_support_description'),
      icon: FaWhatsappSquare
    },


    {
      name: t('home.features_payment'),
      description: t('home.features_payment_description'),
      icon: FaShieldAlt
    },
  ];


  const categories = [

    { key: "0", value: "0", name: t("categories.0"), link: "/" },
    { key: "1", value: "1", name: t("categories.1"), link: "/" },
    { key: "2", value: "2", name: t("categories.2"), link: "/" },
    { key: "3", value: "3", name: t("categories.3"), link: "/" },
    { key: "4", value: "4", name: t("categories.4"), link: "/" },
    { key: "5", value: "5", name: t("categories.5"), link: "/" },
    { key: "6", value: "6", name: t("categories.6"), link: "/" },
    { key: "7", value: "7", name: t("categories.7"), link: "/" },
    { key: "8", value: "8", name: t("categories.8"), link: "/" },

  ];

  const carouselOptions = {
    showThumbs: false,
    autoPlay: true,
    infiniteLoop: true,
    interval: 3500,
  }

  const carouselItems = [
    {
      image: banner1,
      title: "Title 1",
      description: "Description 1",
    },
    {
      image: banner2,
      title: "Title 2",
      description: "Description 2",
    },
    {
      image: banner3,
      title: "Title 3",
      description: "Description 3",
    },
  ];



  const products = [
    {
      id: 1,
      name: 'Zip Tote Basket',
      color: 'White and black',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg',
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      price: '$140',
    },

    {
      id: 2,
      name: 'Zip Tote Basket',
      color: 'White and black',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg',
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      price: '$140',
    },

    {
      id: 3,
      name: 'Zip Tote Basket',
      color: 'White and black',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg',
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      price: '$140',
    },

    {
      id: 4,
      name: 'Zip Tote Basket',
      color: 'White and black',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg',
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      price: '$140',
    },
  ]



  return (
    <>

      <div className='py-8'>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className='grid gap-2 grid-cols-1 lg:grid-cols-12'>
            <div className='hidden lg:block lg:col-span-3 px-4 bg-white'>
              <h2 className='h-12 p-2 flex items-center text-lg font-semibold uppercase bg-tutto-secondary text-white'>
                <FaBars className='mr-2' /> {t("categories.title")}
              </h2>
              <ul className='p-2 flex flex-col ring-1 ring-gray-200'>
                {
                  categories.map(category => {
                    return (
                      <li className='border-b border-gray-200 last:border-b-0' key={category.key}>
                        <a className='h-8 p-1 flex items-center text-tutto-primary text-sm' href={category.link} >{category.name}</a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className='col-span-12 lg:col-span-9'>
              <Carousel {...carouselOptions}>
                {carouselItems.map((item, index) => (
                  <div key={index}>
                    <img src={item.image} alt={item.title} />
                    <p className="legend">{item.title}<br />{item.description} </p>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>


      <div className="py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-2 overflow-hidden sm:mx-0 sm:rounded-sm md:grid-cols-3 bg-white">
            {features.map((feature) => (
              <div className="p-4 flex justify-center items-center ring-1 ring-inset ring-gray-300" key={feature.name}>
                <feature.icon className="text-4xl text-tutto-primary" />
                <div className='flex flex-col items-start pl-2'>
                  <h2 className="text-center text-base font-semibold leading-8 text-tutto-primary">
                    {feature.name}
                  </h2>
                  <p className="text-center text-sm leading-6">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      <Brands />


      <div className="py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-2xl font-semibold leading-8 text-tutto-primary py-4">
            {t("home.section_products")}
          </h2>

          <div className="mt-8 grid grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4">
            {products.map((product) => (
              <div key={product.id} className=''>
                <div className="relative">
                  <div className="relative h-72 w-full overflow-hidden rounded-lg">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div className="relative mt-4">
                    <h3 className="text-sm font-semibold text-tutto-primary">{product.name}</h3>
                    <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                  </div>
                  <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                    <div
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-36 -bg-gradient-to-t -from-black -opacity-50"
                    />
                    <p className="relative text-lg font-semibold text-red-500">{product.price}</p>
                  </div>
                </div>
                <div className="mt-6">
                  <a
                    href={product.href}
                    className="relative flex items-center justify-center  border border-transparent bg-tutto-primary text-white px-8 py-2 text-sm font-medium hover:bg-tutto-secondary"
                  >
                    Add to bag<span className="sr-only">, {product.name}</span>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className="py-8">
        <div className="mx-6 lg:mx-8 max-w-7xl py-6 bg-white border-t border-b border-tutto-primary">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-2 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">

            <div className="relative overflow-hidden  bg-tutto-primary px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <img
                className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
                src="https://tuttocapsule-ks.com/wp-content/uploads/2022/03/tutto-dyqani-1-1.jpg"
                alt=""
              />
              <div className="absolute inset-0 bg-tutto-accent mix-blend-multiply" />

              <figure className="relative isolate">

                <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                  <p>
                    “Sumérgete en el mundo del café de calidad con nuestra amplia gama de cápsulas, granos, café molido y pods cuidadosamente seleccionados.”
                  </p>
                </blockquote>
                <figcaption className="mt-6 text-sm leading-6 text-gray-300">
                  <strong className="font-semibold text-white">Valeria,</strong> Tuttocapsule, Quito
                </figcaption>
              </figure>
            </div>

            <div className="text-base leading-7 text-gray-700">
              <h2 className="mt-2 text-2xl font-bold tracking-tight text-tutto-primary sm:text-4xl">
                {t("about.subtitle")}
              </h2>
              <div className="max-w-xl">
                <p className="mt-6 whitespace-pre-wrap">
                  {t("about.detail")}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className='pt-8'>
        <h2 className="text-center text-2xl font-semibold leading-8 text-tutto-primary py-4">
          {t("home.section_shop")}
        </h2>
        <iframe
          title='google map'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.9011963813364!2d-78.5001740555725!3d-0.16189600000000617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59bc59b127625%3A0xae00d3e58e18b26e!2sJade%20Cafe%20Desing!5e0!3m2!1szh-CN!2sec!4v1702494178183!5m2!1szh-CN!2sec"
          className='w-full h-[400px] mt-8'
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  )
}
