// const environment = process.env.NODE_ENV || 'development';

const CA =`\
-----BEGIN CERTIFICATE-----
QhqpDgefoya2SU+BKtE+eQU2FsdGVkX18xSToSDAfpSPhPikYW6Q1kTu0VNPKdCXEEVK07M9mlWosH5bzEF9ywWWU+9P2ynVDrWU4ACxdR4lUUh24tDmxKz3vIkrYUjvEkFeu1MjGDDVtUcoCQSTURWxFMAkP9/wGxe0siw0PwvVqhHX327p1MmuS1tfjwJPQ=
jKT5QDFbP/GHlVNWv7CuewU2FsdGVkX1+6a1Nm4Lc+THJwVYphNu2cqk3DgtjuTeEyOQ+8K+Igad4TbRxpMWV0NuR8RBMhoAewTGk6eHZZuo7zbGwxXUYat3mIyglCSpcAvROF8zeDtBLXX+Qpu7Tt46gj46iRPrLy/ik68kZwiKn0FGLd43O2x60halk9kag=
7UunS6223hK1FkvBCJo5KQU2FsdGVkX1+bNNY9GIIPXiwUJiNBlCXFSLsFNm0C6O05eZ+CvssEP7sEJzB3ruIZ62ZaH3+0iI2SW+xzoVxUaComUb61M0bpEWeJ+P8SEI1zSeK/imBZUD9gFLzn+gko/OtuDMZ7gRaqnYFWfDDAgfbunbcrRR0r/I+JmeAOTMg=
bL5oSHM9tUoNVgXV3guVkwU2FsdGVkX19tcd5BtJhzoWUnGjYB/64g4+fS3GcfiyFhNyfxoOrqFHkzguDOmyTOuebFSs9GlPowSzsv95GQ8Su8wzAqkbUMwfp0A9Y3SpWZ0VDll+VyqkjWPWnNlIP+4llIUAtNJ7+5hBuMFU4Qoq+PS6MHw/5BnlZ/FiYKx2M=
2aq7I0Ku7diT15XouPzwoAU2FsdGVkX197OJH50AfMKo+a7ZU/CnI3cYxl4nvJh8jkaBuBjBxgE6+xJqqyJ/X5YWwtoLBUoXdlG9R9IOoFzEkBhq9LTIiQ+TCuD+E5UYVooZEnztp8Zw+O3ikHUQDjxjFgmSFtQNxwsAhEqr4d/i67gdj6DwFsYf3pRcxjoFo=
QIOP2TZESITJJ/BxVgay4QU2FsdGVkX198i84CUIPG8CE+Ny0E5qi2f2QQagp3r/yx3VJHx7cRjpeqSxYoy4NtuUSRrFFbJYcSIlXqMN4joXUuGwMppMqkT51+TNDWpf5OMFGGkfFMuHiIqLV48pDVryKH+SMt7xKdDvShGyQ80EbkRxoulFfjcEZ7TVqfCcA=
PfefjRJJDpmrEln4OFwk8gU2FsdGVkX1/XGSwGpp1DAuV6hamRGsOUdwnIJ9zGbIvxnCKJiBlwYO60jkG2KpT25IBUr9QzWsxuIxCi527xGcjWb2cQKnVTi5fLYHoHo6/QHjh6wEEs48jMsDolXp/DTW7Vd4D9GBpinStQrBVgSncLucHnbo0eYLPruzXccss=
EBdytvjQ5xBnYZ0o7yNFAgU2FsdGVkX1/N7WTHehknRPcD9YMmpIo/NqtGF33q5BR24dilrQn+FcPZDYnWJ2vZ1Lnstoaj+4tbt96d9NWhiuut5NSbtC4MbeyxKF396nAkSpE1m/q8Fd2mZApBooxJUyMCFDGWMPqp0h4xWNgcnvGYqcK91couMJ06zkDor2k=
-----END CERTIFICATE-----
`;


const Settings = {
    simulation: false,
    tk: 'cotoken',
    ah: 'https://devapi.betasys.cn/v2', //for portal  rw
    ak: ['please', 'contact', 'joe#wzhu.net'].join('-'), 
    as: ['for', 'a', 'license'].join('-'),
    ds: ['all', 'rights', 'reserved'].join('-'),
}

const AMapSettings = {
    key: "8210f1c05ea676120e284a84c1106a1c", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0",                          // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    token: '_AMap_Token',
    // plugins: ['AMap.AutoComplete'],
    // plugins:['AMap.ToolBar','AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Geocoder'],  // 需要使用的的插件列表，如比例尺'AMap.Scale'等
};

const Enums = {
    'user_types'         :[{value:0, label:"请刷新以重新加载"}], //用户类型
    'auth_types'         :[{value:0, label:"请刷新以重新加载"}], //账户类型
    "event_types"        :[{value:0, label:"请刷新以重新加载"}], //活动类型
    "ticket_types"       :[{value:0, label:"请刷新以重新加载"}], //票务类型
    "institution_types"  :[{value:0, label:"请刷新以重新加载"}], //机构类型
    "institution_levels" :[{value:0, label:"请刷新以重新加载"}], //机构级别 
    'collaborator_levels':[{value:0, label:"请刷新以重新加载"}], //合作伙伴级别
    "folder_tags"        :[{value:0, label:"请刷新以重新加载"}], //文件夹标签
    "entry_statuses"     :[{value:0, label:"请刷新以重新加载"}], //作品状态
    "entry_levels"       :[{value:0, label:"请刷新以重新加载"}], //作品级别
    "entry_groups"       :[{value:0, label:"请刷新以重新加载"}], //作品赛道
}

const Categories = {
    'entry' :  [{value:0, label:"请刷新以重新加载"}],
};



export { Settings, AMapSettings, CA, Enums, Categories };
