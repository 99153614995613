
import React from "react";
import {createBrowserRouter} from "react-router-dom";

// import Layout = React.lazy(() => import("../components/Layout/Frame"));
import Loader from "../components/Loader/index.jsx";

import Layout from "../layout/index.jsx";

import Home     from "../pages/Home/index.jsx";
import Products from "../pages/Products/index.jsx";
import Overview from "../pages/Products/Overview.jsx";
import Carts    from "../pages/Carts/index.jsx";
import Contact  from "../pages/Contact/index.jsx";
import Shop     from "../pages/Shop/index.jsx";
import About    from "../pages/About/index.jsx";
import Error404 from "../pages/Error/404.jsx";


const router = createBrowserRouter(
    [

        // { path: "/auth/signin", Component: Signin,   loader: Loader,},
        // { path: "/auth/signup", Component: Signup,   loader: Loader,},
        { path: "/",            Component: Layout,   loader: Loader, errorElement: <Error404 />, children: [
            { index: true,      Component: Home,     loader: Loader,},
            { path: "home",     Component: Home,     loader: Loader,},
            { path: "products", children: [
                { index: true,      Component: Products, loader: Loader,},
                { path: ":productId", Component: Overview, loader: Loader,},
            ]},
            { path: "carts",    Component: Carts,    loader: Loader,},
            { path: "contact",  Component: Contact,  loader: Loader,},
            { path: "shop",     Component: Shop,     loader: Loader,},
            { path: "about",    Component: About,    loader: Loader,},
        ]},
      ]
  );

export default router;