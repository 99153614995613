import React, { Suspense } from 'react'

import { Outlet } from 'react-router-dom'

import Banner from '../components/Banner'
import Navbar from './Navbar'
import Footer from './Footer'

// import Loader from '../components/Loader/index.jsx'
import Loader from '../components/Loader'

import bgImg from '../assets/images/bg.jpg'

export default function Layout() {

  return (
    <>
      <Banner />
      <Navbar />
      <main className="w-full bg-no-repeat bg-cover" style={{backgroundImage: `url(${bgImg})`, backgroundPosition: 'fixed'}}>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
    </>
  )
}
